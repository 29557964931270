import React, { useEffect } from 'react';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
// import ContactUsSection from '../../../Components/ContactUsSection';
import StayConnected from '../../../Components/StayConnectedSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
// import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import Coral_Ridge_1 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import Coral_Ridge_2 from '../../../assets/images/Coral-Ridge-Residenc-P-2.jpg';

export default function CoralRidgeResidencesProject() {
  useEffect(() => {
    document.title = 'Starlife: The Residences at Coral Ridge - 4 Townhomes';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title: 'The Residences at Coral Ridge: Luxury Townhouses Redefined',
    price: '$1.700.000 - $21.900.000',
    location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
    images: [
      {
        original: Coral_Ridge_1,
        thumbnail: Coral_Ridge_1,
      },
      {
        original: Coral_Ridge_2,
        thumbnail: Coral_Ridge_2,
      },
    ],
    text: `Introducing The Residences at Coral Ridge, a refined collection of four luxury townhouses located in the prestigious Coral Ridge neighborhood of Fort Lauderdale. Building upon the sophistication and elegance of the Villas at Coral Ridge, this project offers a modern living experience designed for those who appreciate the finer things in life.

Each townhouse in this exclusive project features three spacious bedrooms and spans between 2,400 to 2,600 square feet of thoughtfully designed living space. With an emphasis on modern elegance, the interiors are crafted with high-end finishes and open layouts, ensuring that every corner of your home exudes luxury and comfort.

The Residences at Coral Ridge also feature a stunning rooftop terrace, offering over 1,000 square feet of outdoor living space—perfect for entertaining guests, enjoying alfresco dining, or simply soaking up the sun. Residents will appreciate the private pool that complements the outdoor lifestyle, providing a tranquil oasis right at home.

Designed for the discerning homeowner, these townhouses are an embodiment of contemporary luxury living, seamlessly blending style and functionality. With easy access to the best amenities that Coral Ridge has to offer, including elite golf courses, yacht clubs, and proximity to the beach, The Residences at Coral Ridge position themselves as the ultimate choice for those seeking an upscale lifestyle.

Experience the epitome of modern luxury at The Residences at Coral Ridge, where sophisticated design meets the vibrant lifestyle of Fort Lauderdale.
`,
  };
  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    // {
    //   project_name: 'The Residences at Coral Ridge',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
    //   pic: P_6,
    //   link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    // },
    {
      project_name: 'The Villas at Dania Beach',
      price: '$1.700.000 - $21.900.000',
      location: '20 SW 15th St, Dania Beach, FL, 33004',
      pic: P_7,
      link: ROUTE_NAMES.DANIABEACHVILLAS,
    },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.524143272056!2d-80.11605652507855!3d26.147054192575546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d901c24970f80f%3A0x5759750c7f34c00b!2zMTUyMSBORSAyNnRoIEF2ZSwgRm9ydCBMYXVkZXJkYWxlLCBGTCAzMzMwNCwg0KHQqNCQ!5e0!3m2!1sru!2sam!4v1743148151231!5m2!1sru!2sam'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
