import React, { useEffect } from 'react';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
// import ContactUsSection from '../../../Components/ContactUsSection';
import StayConnected from '../../../Components/StayConnectedSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
// import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import Dania_Beach_P_1 from '../../../assets/images/Dania-Beach-P-1.jpg';

export default function DaniaBeachVillasProject() {
  useEffect(() => {
    document.title = 'Starlife: The Villas at Dania Beach - 4 Townhomes';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title: 'The Villas at Dania Beach: Luxury Living with a Coastal Touch',
    price: '$1.700.000 - $21.900.000',
    location: '20 SW 15th St, Dania Beach, FL, 33004',
    images: [
      {
        original: Dania_Beach_P_1,
        thumbnail: Dania_Beach_P_1,
      },
    ],
    text: `Introducing The Villas at Dania Beach, an exclusive collection of four luxurious townhomes set in a stunning gated community just moments from the sparkling Atlantic Ocean and the vibrant Dania Pointe. This project epitomizes modern coastal living, where luxury meets convenience in one of Florida's most sought-after locations.

Each three-story townhome features three beautifully appointed bedrooms, designed to provide comfort and elegance for families and individuals alike. The thoughtfully laid-out floor plans maximize the use of space while ensuring an airy and inviting atmosphere.

One of the standout features of The Villas at Dania Beach is the expansive rooftop patio deck, offering a perfect retreat for relaxation and entertaining. Residents can savor breathtaking views, enjoy al fresco dining, or simply bask in the sun from their own private oasis.

The design and construction of this project were not without challenges, as the unique layout of the lot required innovative solutions. However, the end result is a collection of exquisite townhouses that will undoubtedly become cherished homes for their future residents. Each unit blends contemporary architecture with luxurious finishes, creating an inviting environment that feels both stylish and comfortable.

Living at The Villas at Dania Beach means embracing a lifestyle enriched by the nearby coastal beauty and bustling amenities of Dania Pointe. This project offers the ideal balance of luxury living and beachside charm, making it a standout choice for those seeking an upscale residence in South Florida.

Discover the beauty of coastal living at The Villas at Dania Beach, where every townhouse tells a story of luxury, innovation, and a commitment to excellence.
`,
  };
  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    // {
    //   project_name: 'The Villas at Dania Beach',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '20 SW 15th St, Dania Beach, FL, 33004',
    //   pic: P_7,
    //   link: ROUTE_NAMES.DANIABEACHVILLAS,
    // },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.9630862261934!2d-80.14646092508215!3d26.03476379726891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9aa554c9eb969%3A0xacd396f8257ff968!2zMjAgU1cgMTV0aCBTdCwgRGFuaWEgQmVhY2gsIEZMIDMzMDA0LCDQodCo0JA!5e0!3m2!1sru!2sam!4v1743148358971!5m2!1sru!2sam'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
