import React, { useEffect } from 'react';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
// import ContactUsSection from '../../../Components/ContactUsSection';
import StayConnected from '../../../Components/StayConnectedSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
// import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import StarlifePlaza_1 from '../../../assets/images/Starlife-Plaza-1.jpg';

export default function StarlifePlazaProject() {
  useEffect(() => {
    document.title = 'Starlife: Starlife Plaza - Yerevan, Armenia';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title: 'Starlife Plaza - Commercial Plaza',
    price: '$1.700.000 - $21.900.000',
    location: 'Yerevan, Armenia',
    images: [
      {
        original: StarlifePlaza_1,
        thumbnail: StarlifePlaza_1,
      },
    ],
    text: `Starlife Plaza is more than just a commercial development—it’s a symbol of connection, ambition, and heritage. Set to rise in the heart of Armenia’s vibrant capital, Yerevan, Starlife Plaza is a fusion of contemporary design and cultural roots. Developed by Starlife Group, this ambitious project marks a homecoming for our company, honoring the place where our journey began.

With three expansive stories dedicated to commercial and retail spaces, Starlife Plaza will become a hub for thriving businesses and modern consumers alike. The rooftop café and terrace will offer breathtaking views of Yerevan, blending urban energy with relaxation, making it a destination for locals and tourists.

Expected to commence construction in 2025, this project signals the dawn of a new era for Armenia’s commercial landscape. Starlife Plaza will stand as a beacon of growth, opportunity, and innovation, setting a new standard for real estate development in the region. With Starlife Group’s commitment to excellence and community, this landmark project will attract investors, retailers, and entrepreneurs eager to be a part of Yerevan’s urban revival.
`,
  };
  const projects = [
    // {
    //   project_name: 'Starlife Plaza',
    //   price: '$1.700.000 - $21.900.000',
    //   location: 'Yerevan, Armenia ',
    //   pic: P_1,
    //   link: ROUTE_NAMES.STARLIFEPLAZA,
    // },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    {
      project_name: 'The Villas at Dania Beach',
      price: '$1.700.000 - $21.900.000',
      location: '20 SW 15th St, Dania Beach, FL, 33004',
      pic: P_7,
      link: ROUTE_NAMES.DANIABEACHVILLAS,
    },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d195168.53269232443!2d44.17672722094609!3d40.15323464485907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa2dab8fc8b5b%3A0x3d1479ae87da526a!2z0JXRgNC10LLQsNC9!5e0!3m2!1sru!2sam!4v1738139285796!5m2!1sru!2sam&language=en'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>

      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
