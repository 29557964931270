import React, { useEffect } from 'react';
import './style.css';

// import { ROUTE_NAMES } from '../../Routes';

import BlogsSection from '../../Components/BlogsSection';
import GetInTouchSection from '../../Components/GetInTouchSection';
// import ContactUsSection from '../../Components/ContactUsSection';
import StayConnected from '../../Components/StayConnectedSection';

// Project Pics
import P_1 from '../../assets/images/Project1.webp';
import P_2 from '../../assets/images/Project2.webp';
import P_3 from '../../assets/images/Project3.webp';
import P_4 from '../../assets/images/Project4.webp';
import P_5 from '../../assets/images/Project5.webp';
import P_6 from '../../assets/images/Project6.webp';

export default function Blogs() {
  useEffect(() => {
    document.title = 'Starlife: News and Blog';
  }, []);

  const blogs = [
    {
      name: 'Blog',
      date: '25 March, 2025',
      image: P_1,
      link: '',
    },
    {
      name: 'Blog',
      date: '23 March, 2025',
      image: P_2,
      link: '',
    },
    {
      name: 'Blog',
      date: '21 March, 2025',
      image: P_3,
      link: '',
    },
    {
      name: 'Blog',
      date: '24 March, 2025',
      image: P_4,
      link: '',
    },
    {
      name: 'Blog',
      date: '22 March, 2025',
      image: P_5,
      link: '',
    },
    {
      name: 'Blog',
      date: '20 March, 2025',
      image: P_6,
      link: '',
    },
  ];
  return (
    <div className='blogs-page'>
      <BlogsSection blogs={blogs} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
