import React, { useEffect } from 'react';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
// import ContactUsSection from '../../../Components/ContactUsSection';
import StayConnected from '../../../Components/StayConnectedSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
// import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import Coral_Ridge_1 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import Coral_Ridge_2 from '../../../assets/images/Coral-Ridge-P-2.jpg';

export default function CoralRidgeVillasProject() {
  useEffect(() => {
    document.title = 'Starlife: The Villas at Coral Ridge - 3 Townhouses ';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title:
      'The Villas at Coral Ridge: Redefining Luxury Living in Fort Lauderdale',
    price: '$1.700.000 - $21.900.000',
    location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
    images: [
      {
        original: Coral_Ridge_1,
        thumbnail: Coral_Ridge_1,
      },
      {
        original: Coral_Ridge_2,
        thumbnail: Coral_Ridge_2,
      },
    ],
    text: `Introducing The Villas at Coral Ridge, a collection of three ultra-luxurious villas nestled in the prestigious Coral Ridge neighborhood of Fort Lauderdale. Known for its exclusive country club, world-class golf courses, and yacht clubs, Coral Ridge offers residents a lifestyle of sophistication and elegance. This project embodies the pinnacle of upscale living, boasting the largest villas in the area with over 4,000 square feet of interior space under air and an additional 1,200 square feet of rooftop terrace.

Each three-story villa is designed with meticulous attention to detail, featuring four bedrooms, 4.5 bathrooms, and two additional dens that can be customized to suit the needs of any lifestyle. Whether it's an office, fitness room, home theater, or an extra bedroom, these flexible spaces allow for ultimate personalization. The modern minimalist aesthetic is accentuated by sleek lines, high-end finishes, and an open floor plan that brings in natural light and maximizes space.

One of the most impressive features of The Villas at Coral Ridge is the expansive 1,200-square-foot rooftop terrace, designed for the ultimate in relaxation and entertainment. Each rooftop terrace is equipped with a fully-outfitted outdoor kitchen, a luxurious jacuzzi, and a lounge area—creating a perfect space for hosting guests, enjoying sunset views, or unwinding in privacy.

Outdoors, residents will also enjoy a private 26-foot pool and expansive patio deck on the first floor, creating an ideal setting for relaxation and entertainment. Inside, the villas are equipped with upscale custom-made European kitchen cabinetry, top-of-the-line appliances, and smart home systems, providing the latest in convenience and technology.

Located just minutes from the beach, the Galleria Mall, Las Olas Boulevard, and Bayview Elementary School, The Villas at Coral Ridge offers unparalleled access to the best that Fort Lauderdale has to offer. These villas are not just homes; they are a statement of luxury and refinement, designed for those who demand the best in life.
Live the ultimate luxury lifestyle at The Villas at Coral Ridge—where superior living meets timeless elegance in the heart of one of Fort Lauderdale’s most coveted neighborhoods.
`,
  };
  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    // {
    //   project_name: 'The Villas at Coral Ridge',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
    //   pic: P_5,
    //   link: ROUTE_NAMES.CORALRIDGEVILLAS,
    // },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    {
      project_name: 'The Villas at Dania Beach',
      price: '$1.700.000 - $21.900.000',
      location: '20 SW 15th St, Dania Beach, FL, 33004',
      pic: P_7,
      link: ROUTE_NAMES.DANIABEACHVILLAS,
    },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.405153474694!2d-80.11557492507838!3d26.150931492413147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d901e9b809cc21%3A0xa359d2fa7f89164b!2zMTgyNSBORSAyNnRoIEF2ZSwgRm9ydCBMYXVkZXJkYWxlLCBGTCAzMzMwNSwg0KHQqNCQ!5e0!3m2!1sru!2sam!4v1743147965092!5m2!1sru!2sam'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>

      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
