import React, { useEffect } from 'react';
import './style.css';

import OurFoundersSection from '../../Components/OurFounders';
import GetInTouchSection from '../../Components/GetInTouchSection';
// import ContactUsSection from '../../Components/ContactUsSection';
import StayConnected from '../../Components/StayConnectedSection';

export default function Team() {
  useEffect(() => {
    document.title = 'Starlife: Team';
  }, []);
  return (
    <div className='team-page'>
      <OurFoundersSection />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
