import React, { useEffect } from 'react';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
// import ContactUsSection from '../../../Components/ContactUsSection';
import StayConnected from '../../../Components/StayConnectedSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
// import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import Mansion_Riverland_P_1 from '../../../assets/images/Mansion-Riverland-P-1.jpg';

export default function RiverlandMansionProject() {
  useEffect(() => {
    document.title = 'Starlife: The Mansions at Riverland - 11 Townhouses ';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title:
      'The Mansions at Riverland: Tulum-Inspired Luxury in Fort Lauderdale',
    price: '$1.700.000 - $21.900.000',
    location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
    images: [
      {
        original: Mansion_Riverland_P_1,
        thumbnail: Mansion_Riverland_P_1,
      },
    ],
    text: `Discover The Mansions at Riverland, an exclusive gated community of 11 luxurious townhomes, designed to immerse residents in a tranquil and natural setting while offering the utmost in modern living. Situated in the vibrant and rapidly growing area of Fort Lauderdale, FL, this unique project by Starlife Group introduces a touch of Tulum to South Florida, blending modern minimalism with earthy elegance.

Each two-story townhome spans between 2,500 to 3,000 square feet and offers four spacious bedrooms, crafted for those who seek both comfort and style. The design embraces open spaces and natural light, with large windows, seamless indoor-outdoor living, and structural layouts that maximize flow and space. The architecture features abundant natural stone and wood, providing a grounded, earthy aesthetic that resonates with the laid-back yet luxurious vibes of Tulum.

Every residence comes complete with two car spaces, a private pool, a patio, and a dedicated BBQ area—perfect for entertaining and relaxing. The community’s appeal extends to young professionals, couples, and families, offering a serene retreat from the hustle and bustle, while still being minutes away from Fort Lauderdale’s lively downtown and beach scene.

The Mansions at Riverland isn't just a place to live—it's an experience, combining modern design with nature to create a harmonious living environment. With a focus on creating ample open space within each home, this project ensures both privacy and a sense of community, offering an oasis of calm in a prime, up-and-coming location.
Elevate your lifestyle at The Mansions at Riverland, where luxury meets simplicity, and every detail is designed for modern living.
`,
  };
  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    // {
    //   project_name: 'The Mansions at Riverland',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
    //   pic: P_3,
    //   link: ROUTE_NAMES.RIVERLANDMANSION,
    // },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    {
      project_name: 'The Villas at Dania Beach',
      price: '$1.700.000 - $21.900.000',
      location: '20 SW 15th St, Dania Beach, FL, 33004',
      pic: P_7,
      link: ROUTE_NAMES.DANIABEACHVILLAS,
    },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.0546797817956!2d-80.18545802508008!3d26.09713369466438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d900b2165ebe53%3A0x63a41cc9e153676d!2zMTk1NSBTVyAyOXRoIEF2ZSwgRm9ydCBMYXVkZXJkYWxlLCBGTCAzMzMxMiwg0KHQqNCQ!5e0!3m2!1sru!2sam!4v1743147299055!5m2!1sru!2sam'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>

      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
