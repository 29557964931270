import React, { useEffect } from 'react';
import './style.css';

import AboutUsSection from '../../Components/AboutUsSection';
import GlimpsSection from '../../Components/GlimpsSection';
import GetInTouchSection from '../../Components/GetInTouchSection';
// import ContactUsSection from '../../Components/ContactUsSection';
import StayConnected from '../../Components/StayConnectedSection';

export default function AboutUs() {
  useEffect(() => {
    document.title = 'About Starllife Group';

    const keywordsContent =
      'Exclusive real estate investment, Luxury office spaces, Real estate development company, Starlife Group properties';

    let metaKeywords = document.querySelector('meta[name="keywords"]');

    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      document.head.appendChild(metaKeywords);
    }

    metaKeywords.setAttribute('content', keywordsContent);
  }, []);
  return (
    <div className='about-us-page'>
      <AboutUsSection />
      <GlimpsSection />
      <GetInTouchSection />
      <StayConnected />
      {/* <ContactUsSection /> */}
    </div>
  );
}
