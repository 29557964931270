import React, { useEffect } from 'react';
import './style.css';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
// import ContactUsSection from '../../../Components/ContactUsSection';
import StayConnected from '../../../Components/StayConnectedSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';
import DownloadHollywoodPDFSection from '../../../Components/DownloadHollywoodPDFSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
// import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import HollywoodPic_1 from '../../../assets/images/Hollywood-P-1.jpg';
import HollywoodPic_2 from '../../../assets/images/Hollywood-P-2.jpg';

export default function HollywoodProject() {
  useEffect(() => {
    document.title = 'Starlife: 21 Hollywood - Hollywood, FL, USA';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title:
      '21 Hollywood: Redefining Luxury Living in the Heart of Hollywood, FL',
    price: '$1.700.000 - $21.900.000',
    location: '2100 N Federal Hwy, Hollywood, FL, 33020',
    images: [
      {
        original: HollywoodPic_1,
        thumbnail: HollywoodPic_1,
      },
      {
        original: HollywoodPic_2,
        thumbnail: HollywoodPic_2,
      },
    ],
    text: `21 Hollywood is set to transform the skyline of Miami and the city of Hollywood with its striking presence and unrivaled amenities. This 14-story, 200-unit luxury rental and mixed-use masterpiece is a collaboration between Starlife Group and the globally renowned architect Kobi Karp. Located just minutes from the Atlantic Ocean and Hollywood's vibrant downtown, this development is one of the most anticipated projects in the region.

Designed to inspire, 21 Hollywood features 10,000 square feet of prime commercial space and delivers a world-class living experience. Residents will enjoy a host of advanced amenities, including a stunning business center with 16-foot ceilings, co-working spaces, and a conference room tailored for professionals. The building will also be equipped with the most advanced smart systems and cutting-edge technology to provide seamless convenience and luxury living.

For health and wellness enthusiasts, the building offers a state-of-the-art fitness center with 14-foot ceilings, complemented by a dedicated yoga and Pilates room. Equipped with the finest workout equipment available globally, this wellness area is designed to cater to all aspects of physical and mental well-being. Built with sustainability in mind, 21 Hollywood will use eco-friendly materials and embrace natural design elements throughout, blending modern luxury with environmental consciousness.
The thoughtfully curated amenities extend to a children's play area, a dog park complete with a dog spa and wash, and two cinemas—one indoors and one outdoors—providing the ultimate entertainment options. A clubhouse, designed for celebrations, holidays, or simply spending quality time, is fully equipped with a kitchen, lounge, and game room for residents to unwind and entertain.

Residents can also relax in the sauna, jacuzzis, or the 85-foot infinity pool. Social gatherings will thrive in the outdoor BBQ area with summer kitchens, cabanas, and various lounges spread throughout the building. Full concierge service ensures a seamless living experience, while the penthouse units boast luxurious rooftop terraces for those seeking the pinnacle of opulence.

21 Hollywood is more than a place to live—it’s a lifestyle destination where residents can work, relax, and entertain, blending the best of California and Miami styles. The project’s significance extends beyond design and luxury, as it also qualifies for the EB-5 investment program, offering foreign investors a unique opportunity to invest in this iconic development and obtain U.S. residency.

This project represents the future of luxury living, destined to become a landmark that will leave a lasting legacy in South Florida.
`,
  };
  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    // {
    //   project_name: '21 Hollywood',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '2100 N Federal Hwy, Hollywood, FL, 33020',
    //   pic: P_2,
    //   link: ROUTE_NAMES.HOLLYWOOD,
    // },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    {
      project_name: 'The Villas at Dania Beach',
      price: '$1.700.000 - $21.900.000',
      location: '20 SW 15th St, Dania Beach, FL, 33004',
      pic: P_7,
      link: ROUTE_NAMES.DANIABEACHVILLAS,
    },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.099510641962!2d-80.1462619250823!3d26.03029989745509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9abaa6d3f33b9%3A0xc2c60b28d83d4594!2zMjEwMCBOIEZlZGVyYWwgSHd5LCBIb2xseXdvb2QsIEZMIDMzMDIwLCDQodCo0JA!5e0!3m2!1sru!2sam!4v1743145962270!5m2!1sru!2sam'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
      <div style={{ marginTop: '5em' }}>
        <DownloadHollywoodPDFSection />
      </div>

      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
