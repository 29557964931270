import React, { useEffect } from 'react';
import './style.css';

import GetInTouchSection from '../../Components/GetInTouchSection';
// import ContactUsSection from '../../Components/ContactUsSection';
import StayConnected from '../../Components/StayConnectedSection';

import RightArrow from '../../assets/images/right-arrow.png';
import DownloadPDFSection from '../../Components/DownloadPDFSection';

export default function EBService() {
  useEffect(() => {
    document.title = 'Starlife: EB-5 | US Residency through Investment';
  }, []);
  return (
    <div className='eb-page'>
      <div className='service-context'>
        <h2 className='service-page-title'>
          EB-5 | US Residency through Investment
        </h2>
        <p className='service-page-text'>
          Where can I get some?<br></br>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suered alteration in some form, by injected humour,
          or randomised words which don't look even slightly believable. If you
          are going to use a passage of Lorem Ipsum, you need to be sure there
          isn't anything embarrassing hidden in the middle of text. All the
          Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p>
      </div>
      <DownloadPDFSection />
      <div className='our-services-section'>
        <h2 className='section-title'>Other Services</h2>
        <div className='service-list'>
          <div className='service-block green-block'>
            <h3>Development</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <div className='service-block'>
            <h3>Construction</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <div className='service-block green-block'>
            <h3>Real Estate Brokerage & Mortgage Services</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <div className='service-block'>
            <h3>Capital venture</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
        </div>
      </div>
      <GetInTouchSection />
      {/* <ContactUsSection /> */}
      <StayConnected />
    </div>
  );
}
