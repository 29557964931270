import React, { useState } from 'react';
import './style.css';

import { SendForm } from '../../Platform/SendForm';

import { BsWhatsapp } from 'react-icons/bs';
// import { RiFacebookCircleLine } from 'react-icons/ri';
import { CgInstagram } from 'react-icons/cg';
import { PiLinkedinLogoBold } from 'react-icons/pi';

export default function StayConnected() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    if (error) {
      setError('');
    }
  };

  const handleSubmit = async () => {
    const { email } = formData;

    if (!email.trim()) {
      setError('Please fill out all required fields.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    let data = {
      Mail: formData.email,
      Origin: 'Stay Connected',
    };

    try {
      await SendForm(data);
      setSuccess(true);
      setError('');
    } catch (error) {}
  };

  return (
    <div className='contact-us-section stay-connected-section'>
      <div className='contact-us-inner-section'>
        <h2 className='section-title'>Stay Connected</h2>
        <p>Register for the related Newsletter</p>
        <div className='form-section'>
          <div className='input-group'>
            <input
              id='email'
              type='email'
              name='email'
              placeholder='Your Email Address'
              value={formData.email}
              onChange={handleChange}
            />
            <label htmlFor='email' onClick={handleSubmit}>
              Submit
            </label>
          </div>
          {error && <p className='error-message'>{error}</p>}
          {success && (
            <p className='success-message'>
              Form successfully submitted, we will contact you
            </p>
          )}
          <div className='col-3'>
            <a
              href='https://wa.me/13059157737'
              target='_blank'
              rel='noopener noreferrer'
            >
              <BsWhatsapp className='icon' />
            </a>
            {/* <a href="#" target="_blank" rel="noopener noreferrer">
                    <RiFacebookCircleLine className="icon f-icon" />
                  </a> */}

            <a
              href='https://www.instagram.com/thestarlifegroup/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <CgInstagram className='icon' />
            </a>
            <a
              href='https://www.linkedin.com/company/starlifegroup'
              target='_blank'
              rel='noopener noreferrer'
            >
              <PiLinkedinLogoBold className='icon' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
