import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import { ROUTE_NAMES } from '../../Routes';

import Logo from '../../assets/images/StarlifeLogo.png';

import { BsWhatsapp } from 'react-icons/bs';
// import { RiFacebookCircleLine } from 'react-icons/ri';
import { CgInstagram } from 'react-icons/cg';
import { PiLinkedinLogoBold } from 'react-icons/pi';

export default function Footer() {
  return (
    <div className='footer'>
      <div className='col-1'>
        <img src={Logo} alt='logo' />
        <div>
          <a href='tel:+13059157737'>
            <h3>(305) 915-7737</h3>
          </a>
          <a href='mailto:Info@thestarlifegroup.com'>
            <h3>Info@thestarlifegroup.com</h3>
          </a>
        </div>
      </div>
      <div className='col-2'>
        <ul id='navbar'>
          <li>
            <NavLink to={ROUTE_NAMES.HOME} end>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTE_NAMES.COMPANY} end>
              Company
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTE_NAMES.PROJECTS} end>
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTE_NAMES.TEAM} end>
              Team
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTE_NAMES.BLOG} end>
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTE_NAMES.INVEST} end>
              Invest in project
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTE_NAMES.CONTACT} end>
              Contact us
            </NavLink>
          </li>
        </ul>
      </div>
      <div className='col-3'>
        <a
          href='https://wa.me/13059157737'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsWhatsapp className='icon' />
        </a>
        {/* <a href="#" target="_blank" rel="noopener noreferrer">
          <RiFacebookCircleLine className="icon f-icon" />
        </a> */}

        <a
          href='https://www.instagram.com/thestarlifegroup/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <CgInstagram className='icon' />
        </a>
        <a
          href='https://www.linkedin.com/company/starlifegroup'
          target='_blank'
          rel='noopener noreferrer'
        >
          <PiLinkedinLogoBold className='icon' />
        </a>
      </div>
    </div>
  );
}
